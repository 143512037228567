<template>
  <div class="relative">
    <!--
    <svg version="1.1" id="logo1" class="logo absolute" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 450" style="enable-background:new 0 0 500 450;" xml:space="preserve">
      <path class="st0 op1" d="M18,315.4c3.8,0.5,9.2,0.8,16.2,0.8h19.1c1.2-4.9,2.1-10.2,2.9-15.9c4.4,1.4,8.1,2.6,11,3.5
        c4.4,1.5,6.6,2.9,6.6,4.1c0,1.1-1,1.9-2.9,2.4c-1.8,0.4-2.7,1-2.8,1.7c-0.5,1.5-0.9,2.9-1.3,4.1h21.6c0.3-0.1,1.6-1.7,3.9-4.9
        c2.9-3.7,4.6-5.5,5.2-5.5c1.6,0,4.1,1.8,7.5,5.4c3.4,3.5,5.1,5.6,5.1,6.4c0,1.3-0.8,1.9-2.3,1.9H65.7c-1.4,4-2.8,7.7-4.1,11h19.9
        c0.3-0.2,1.6-1.7,3.9-4.6c2.8-3.3,4.4-5,4.9-5c1.4,0,3.6,1.6,6.7,4.9c3.1,3.3,4.7,5.3,4.7,5.9c0,1.3-0.7,1.9-2.2,1.9H74.7
        c3.1,4.6,6.3,8.7,9.8,12.2h9.9c0.3-0.2,1.7-1.9,4.3-5.1c3.1-3.8,4.9-5.7,5.4-5.7c1.5,0,4.1,1.8,7.6,5.5c3.5,3.7,5.2,5.9,5.2,6.7
        c0,1.3-0.7,1.9-2.2,1.9H88.1c2.4,2.1,4.8,3.9,7.4,5.4c8,4.7,16.1,8.1,24.2,10.2l-0.5,2.2c-3.2,1.1-5.7,3.3-7.3,6.7
        c-0.8,2.6-1.5,3.9-2.3,3.9c-1.5,0-4.9-1.9-10.2-5.6c-5.7-4.3-11-9.4-16-15.5c-1.7-2.3-3.3-4.7-4.7-7.2H51.9
        c-3.8,5.2-7.6,9.4-11.4,12.8c4.4,1.1,7.7,2.3,9.9,3.5c2.4,1.6,3.6,3.4,3.6,5.5c0,2.1-0.9,4-2.8,5.8c-1.2,1.4-2.7,2.1-4.7,2.1
        c-1.5,0-2.8-1.4-3.9-4.3c-1.1-3.4-3-6.6-5.6-9.6c-5.9,4.5-13.7,8.8-23.6,13l-1.4-2.4c11-7.6,18.8-14.5,23.6-20.6
        c1.5-1.8,2.9-3.8,4.3-5.8H29c-6.7,0-11.4,0.2-14.1,0.5l-2-4.6c3.8,0.5,9.1,0.8,16.1,0.8h12.8c2.2-3.7,4.2-7.8,6-12.2h-6.3
        c-6.7,0-11.4,0.2-14.2,0.5l-1.7-4.6c3.7,0.6,9,0.9,16,0.9h7.7c1.3-3.4,2.5-7.1,3.4-11H34.2c-6.7,0-11.5,0.2-14.2,0.5L18,315.4z
        M56.6,379.4l0.9,1.3c-6.5,5.5-13.1,9.9-19.6,13.1c-4.3,1.8-6.4,3-6.4,3.4v2.6c-0.2,1.6-0.6,2.4-1.4,2.4c-1,0-2.6-2-4.9-5.9
        c-1.4-1.9-3-4.5-4.7-7.8C30.2,386.9,42.3,383.8,56.6,379.4z M58.4,351.9c6.2,1,10.3,1.8,12.2,2.3c4.7,0.8,7.1,1.9,7.1,3.2
        c0,1.1-1,2.1-2.9,2.9c-2.1,0.5-3.1,1.4-3.1,2.7v12.8c1.4,0.3,2.6,0.6,3.8,0.9c1.1-1.4,2.1-3,3.2-4.8c1.8-2.9,3.4-6.1,4.6-9.5
        c3.2,2,6.2,3.8,8.8,5.6c2.6,1.8,3.9,3.2,3.9,4c0,1-0.6,1.5-1.7,1.5l-5.5-0.5c-2.5,1.5-5.7,3.1-9.7,4.8c4.1,1.2,7.6,2.5,10.6,3.8
        c6,2.7,10.3,5.6,13,8.9c2.3,2.5,3.4,5.2,3.4,8.1c0,1.6-0.5,3-1.6,4.2c-1.1,1.4-2.4,2.1-3.9,2.1c-1.9,0-6-3.6-12.2-10.9
        c-4.6-5-10.2-9.6-16.8-13.7v16.9c0,4.5-1.3,7.7-4,9.6c-3.1,2.1-6.2,3.1-9.3,3.1c-1.1,0-1.9-1-2.2-3c-0.6-3.3-4.9-6.5-13-9.7l0.5-2.4
        c5.8,0.9,10.1,1.4,12.9,1.4c1.9,0,2.9-0.6,2.9-1.8v-31C59.5,361.1,59.1,357.3,58.4,351.9z M76.8,345.9c-2.1-4-4.1-8-5.7-12.2H60.3
        c-2.1,4.9-4.1,9-6.1,12.2H76.8z M152.1,329.6c4.1,1.2,7.6,2.2,10.6,3c1.9-4.6,3.4-8.8,4.6-12.8c1.3-4.8,2.4-10.6,3.2-17.3
        c3.6,0.9,7,1.9,10.3,3c3.2,0.9,4.8,1.9,4.8,3c0,1.1-0.9,1.9-2.8,2.2c-1.9,0.2-3,0.6-3.2,1c-1,2.9-2.1,5.5-3.4,7.9h9.9
        c0.2,0,1.3-1.2,3.3-3.7c2.8-3.4,4.6-5,5.4-5c0.8,0,3.3,1.9,7.4,5.6c2.4,2.4,3.7,3.8,3.7,4.5c0,1.1-0.7,1.7-2.2,1.7h-11.5v18.7h0.5
        c0.2-0.1,1.2-1.2,2.9-3.4c2.3-2.5,3.6-3.8,3.9-3.8c1.2,0,3,1.3,5.5,3.8c1.5,1.5,2.4,2.6,2.9,3.2V325c0-5.2-0.3-11.4-0.9-18.7
        c4.7,1.1,9,2.6,13.1,4.6h5.2c1.1-1.2,1.9-2.2,2.4-3.1c1.9-2.2,3.3-3.3,4.1-3.3c1.1,0,3.4,1.3,6.9,3.8c3.3,2.6,4.9,4.3,4.9,5.3
        c0,0.8-0.8,1.8-2.3,3c-2.1,1.4-3.1,2.7-3.1,4v61.7c0,3.6-1.9,6.3-5.7,8.2c-3,1.5-5.6,2.3-7.7,2.3c-0.6,0-1.2-1.3-1.7-4
        c-0.5-2.1-1.7-3.8-3.8-5.3v21c0,1.4-1.8,2.4-5.3,3c-1.5,0.2-2.7,0.3-3.4,0.3c-2.4,0-3.6-1.1-3.6-3.3c0.6-7.7,0.9-14.5,0.9-20.5
        v-39.5c-0.3,0.2-0.6,0.2-0.9,0.2h-14.7v17.7h1.4c0.7-1.4,1.4-3,2.2-4.7c1.1-2.3,2.1-3.4,2.9-3.4c0.9,0,2.3,1,4.1,3.1
        c2.4,3.3,3.6,5.4,3.6,6.5c0,0.8-0.5,1.3-1.4,1.3h-12.8v19.1c4.7-0.8,9.3-1.6,13.7-2.6l1,1.9c-11.1,7.3-22.9,12.6-35.3,16
        c-1.3,0.1-2,0.6-2,1.3c-0.2,0.4-0.3,1-0.5,1.7c-0.3,1-0.8,1.5-1.4,1.5c-1.6,0-3.5-2.8-5.7-8.3c-0.6-1.5-1.6-4-3-7.5
        c1.9-0.2,3.8-0.3,5.5-0.5v-27.1c0-2.5-0.2-5.1-0.5-7.7c4,0.3,7.8,0.8,11.6,1.5c2.4,0.1,3.6,0.6,3.6,1.6c0,0.5-0.8,1-2.3,1.7
        c-1.5,0.7-2.3,1.5-2.3,2.4v26.4c1.9-0.2,3.7-0.3,5.4-0.6v-41.3h-0.8c-6.7,0-11.5,0.2-14.4,0.5l-1.7-4.6c3.7,0.5,9,0.8,16.1,0.8h0.8
        v-18.7h-5.7c-0.5,0.9-1,1.7-1.5,2.4c-2,2.9-4.4,5.8-7.2,8.6c2.4,0.8,3.6,1.6,3.6,2.3c0,1.1-1.2,1.9-3.7,2.2
        c-1.8,0.4-2.9,0.9-3.3,1.6c-1.7,3.1-3.4,5.7-5.3,8c2.3,0.8,3.4,1.5,3.4,2.1c0,1.1-0.7,2.1-2.1,2.7c-1.1,0.5-1.6,1.5-1.6,2.7v49.7
        c0,1.4-1.8,2.4-5.3,3c-1.5,0.2-2.6,0.3-3.3,0.3c-2.8,0-4.1-1.1-4.1-3.3c0.6-8.2,0.9-16.4,0.9-24.7v-20c-2.6,2.1-5.4,4-8.4,5.8
        l-1.3-1.7c2.9-3.7,6.1-8.7,9.5-15.1C147.5,343.9,149.9,337.4,152.1,329.6z M152.1,303.9c3.9,1.2,7.3,2.2,10.1,3
        c3.8,1.1,5.6,2.1,5.6,3.1c0,0.8-1.1,1.3-3.3,1.5c-1.8,0.3-2.8,0.7-3.2,1.1c-3,4.5-6.2,8.1-9.7,10.9c-3.8,3.5-8.8,6.9-15.2,10.3
        l-0.9-1.5c2.9-3,6.1-7,9.4-12.2C147.6,315.7,150,310.3,152.1,303.9z M219.2,314.1v65c2.2,0.2,4.1,0.3,5.5,0.3c1.3,0,1.9-1.1,1.9-3.3
        v-62.1H219.2z M256.5,345.1c7,0.8,13.5,1.3,19.3,1.3h22.7c0.8-7.9,1.1-18.2,1.1-31h-17.5c-6.2,0-11.4,0.3-15.6,0.8l-1.7-5.4
        c7,0.8,13.4,1.3,19.3,1.3h47.2c0.4-0.1,1.7-1.8,4-4.9c2.9-3.7,4.6-5.6,5.2-5.6c1.6,0,4.1,1.8,7.5,5.4s5.1,5.8,5.1,6.5
        c0,1.3-0.8,1.9-2.4,1.9h-36.3c-0.1,12-0.6,22.4-1.5,31h26.3c0.4-0.2,1.9-1.9,4.5-5.1c3.2-3.8,5.1-5.7,5.6-5.7c1.7,0,4.2,1.8,7.7,5.5
        c3.5,3.7,5.3,5.9,5.3,6.7c0,1.3-0.8,1.9-2.4,1.9h-45.3c2,5.9,4.6,11.5,7.9,16.7c3.7,5.9,8.6,11,14.8,15.3c7.6,5.2,16,9.3,25.4,12.3
        l-0.5,2.7c-4.3,1.5-7,3.6-8.3,6.2c-1.4,3.2-2.6,4.8-3.8,4.8c-1.3,0-3.9-1.4-7.7-4.2c-4.4-2.9-8.9-7-13.7-12.2
        c-3.8-3.9-7.2-9.1-10.2-15.7c-2.7-5.3-4.9-12.5-6.7-21.7c-0.2,0.7-0.4,1.3-0.5,1.9c-2,9.2-5.2,16.9-9.5,23
        c-3.7,5.3-8.7,10.1-14.9,14.5c-7.4,5.3-17.1,10-29.2,13.9l-0.9-1.9c7.8-4,14.5-8.5,20.2-13.5c5.4-5,9.7-10.5,12.6-16.5
        c3.4-6,5.7-12.2,7-18.6c0.5-2,1-4.3,1.4-7.1H274c-6.3,0-11.5,0.3-15.6,0.8L256.5,345.1z M396.1,301.3c4.1,1.1,7.7,2.1,10.7,3
        c4,1.1,6,2,6,2.9c0,1-1.2,1.6-3.7,1.9c-1.3,0.2-2.1,0.5-2.5,0.8c5.6,1.8,9.7,3.6,12.2,5.6c2.8,2.3,4.1,5,4.1,8.2
        c0,1.5-0.7,3-2.1,4.3c-0.9,1.1-2.1,1.6-3.4,1.6c-2.1,0-4-1.8-5.5-5.3c-1.6-4-4.1-7.7-7.5-11.3c-2.7,5.1-5.5,9.3-8.4,12.6
        c-1.9,2.5-4.2,4.9-6.9,7.1c3.4,0.3,7.7,0.5,12.6,0.5c0.2-0.1,1.2-1.2,2.9-3.3c2.3-2.6,3.6-3.9,3.9-3.9c1.2,0,3,1.3,5.5,3.9
        c2.5,2.6,3.8,4.2,3.8,4.8c0,1.1-0.6,1.7-1.8,1.7h-9.2v15.5h0.5c0.2-0.1,1.2-1.3,2.9-3.5c2.2-2.7,3.5-4.1,3.9-4.1
        c1.3,0,3.2,1.4,5.7,4.1c2.6,2.7,3.9,4.3,3.9,5v0.3c3.4,0.4,7.9,0.6,13.4,0.6h6.8v-7.9h-1.7c-6.7,0-11.5,0.2-14.2,0.5l-1.8-4.6
        c3.7,0.7,9,1,16.1,1h14.7c0.2-0.2,1.1-1.2,2.8-3.1c2-2.3,3.2-3.4,3.6-3.4c1,0,2.6,1.2,4.9,3.5c0.4,0.5,0.8,0.8,1.1,1.1
        c0.8-1.5,1.5-3.4,2.3-5.6h-36.4c-5,0-9.7,0.3-14.1,0.9l-2-5.5c4.8,0.9,10.2,1.4,16.1,1.4h36c0.9-0.9,1.7-1.6,2.3-2.2
        c1.8-1.6,3.2-2.4,4-2.4c1.1,0,3.1,1.2,6,3.7c3.1,2.4,4.6,4.1,4.6,5c0,0.8-1.2,1.3-3.7,1.5c-2.1,0.2-3.4,0.5-4,0.9
        c-3.6,2-6.6,3.5-9.1,4.7c0.2,0.4,0.3,0.7,0.3,0.8c0,1.1-0.5,1.7-1.4,1.7h-14.2v7.9h11.8c0.2-0.2,1.3-1.4,3.1-3.8
        c2.1-2.7,3.4-4.1,3.9-4.1c1.2,0,3.1,1.4,5.6,4.1c2.6,2.8,3.9,4.5,3.9,5c0,1.2-0.6,1.8-1.8,1.8H481c0.8,0.8,1.8,1.6,2.8,2.6
        c2.2,2.4,3.3,3.8,3.3,4.3c0,1.1-0.4,1.7-1.3,1.7h-15.6c1.8,0.3,3,0.6,3.7,0.8c4,0.7,6,1.5,6,2.5c0,0.8-0.9,1.6-2.6,2.3
        c-1.6,0.5-2.4,1.2-2.4,2.3v1.7h1.8c0.1-0.1,0.8-0.8,2-2.1c1.5-1.7,2.3-2.5,2.6-2.5c0.8,0,2,0.9,3.8,2.7c1.7,1.8,2.5,2.8,2.5,3.2
        c0,1.3-0.3,1.9-0.8,1.9h-11.9v17.8c0,4.2-1.2,7.1-3.6,8.8c-2.7,2.1-5.4,3.1-8,3.1c-0.8,0-1.3-0.9-1.5-2.7c-0.5-2.9-3.6-5.8-9.4-8.7
        l0.3-2.4c4.6,0.6,8,0.9,10.1,0.9c1.2,0,1.8-0.6,1.8-1.7V379c-6.5,0-11.1,0.2-13.7,0.5l-0.7-1.8c-0.5,0.4-1,0.7-1.5,1
        c-1,0.8-1.5,1.6-1.5,2.5v8.2c0,2.8,0.2,6.6,0.5,11.4c0,1.1-1.6,2-4.8,2.5c-1.4,0.2-2.4,0.3-3.1,0.3c-1.5,0-2.2-1-2.2-2.9v-3.1h-5.1
        v4.6c0,1.1-1.5,1.9-4.5,2.4c-1.3,0.2-2.3,0.2-3,0.2c-2.1,0-3.1-0.9-3.1-2.6c0.3-3.2,0.5-6.4,0.5-9.6v-9.1c0-4.6-0.2-9.1-0.5-13.4
        c3.7,1.1,7.5,2.5,11.4,4.2h0.5c1.5-1.6,2.8-3,4-4.1c0.8-0.9,1.6-1.4,2.4-1.4c0.8,0,2.7,0.8,5.6,2.5c2.3,1.6,3.8,2.8,4.4,3.5
        c3.7,0.5,8.5,0.7,14.4,0.7v-1.3c0-1.8-0.1-4.5-0.3-8.3h-24c-6.7,0-11.4,0.2-14.1,0.5l-2-4.6c3.8,0.7,9.1,1,16.1,1h32.2
        c0.2-0.2,1.2-1.2,2.9-3.1c1-1.1,1.8-1.9,2.3-2.5h-40.4c-6.7,0-11.5,0.2-14.2,0.6l-1-2.7h-15.1v31.3c5.1-0.5,10-1.1,14.7-1.7l0.5,1.4
        c-9.9,6-20.3,10.4-31.4,13.1c-1.1,0.2-1.7,0.5-1.7,1c-0.2,0.4-0.3,0.9-0.3,1.5c-0.2,0.9-0.6,1.4-1,1.4c-1.5,0-3.3-2.6-5.4-7.8
        c-0.6-1.4-1.6-3.7-3-6.9c5.8-0.3,11.3-0.6,16.5-1v-32.3c-6,0-10.2,0.1-12.8,0.3l-2-4.6c3.4,0.6,8.3,0.9,14.7,0.9v-15.5
        c-3.8,0.1-6.7,0.2-8.6,0.5l-0.8-1.6c-1.8,1.5-3.7,3-5.7,4.5l-0.9-1.9c2.9-3.8,6-9.1,9.3-15.9C391.6,316.4,394,309.5,396.1,301.3z
        M382,358.9c3.3,2.4,5.9,5,7.8,7.9c1.8,2.9,2.8,5.6,2.8,8c0,2.9-0.6,4.8-1.7,5.8c-1.5,1.7-3.1,2.5-4.8,2.5c-1.2,0-2.2-0.6-2.9-1.8
        c-0.6-0.8-0.9-3.2-0.9-7.1c0-4.2-0.6-9.1-1.7-14.9L382,358.9z M411.4,359.8c3.1,1.1,6.1,2.2,9,3.3c2.8,1.1,4.3,2.2,4.3,3.4
        c0,0.5-0.7,0.7-2.1,0.8c-1.5,0.2-2.3,0.5-2.5,0.7c-2.8,5.9-6.2,11.4-10.1,16.5l-0.9-0.9C409.9,376.2,410.8,368.3,411.4,359.8z
        M420,311.7c3.7,0.6,9.1,0.9,16.2,0.9h7.7v-1.7c0-3.1-0.3-6.3-0.9-9.6c4.8,0.4,9.5,1,13.9,1.8c2.8,0.2,4.1,0.9,4.1,2.3
        c0,0.5-0.8,1-2.5,1.7c-1.7,0.8-2.5,1.7-2.5,2.9v2.6h10.6c0.2-0.1,1.4-1.4,3.4-4c2.4-3.1,3.8-4.7,4.3-4.7c1.3,0,3.3,1.5,6.1,4.6
        c2.7,3,4,4.8,4,5.6c0,1.2-0.6,1.8-1.8,1.8h-26.5v8.2h7.2c0.2-0.2,1.3-1.3,3.1-3.4c2.1-2.7,3.4-4,3.9-4c1.1,0,2.9,1.3,5.4,4
        c2.4,2.6,3.7,4.2,3.7,4.8c0,1.1-0.6,1.7-1.8,1.7h-35.7c-6.7,0-11.5,0.2-14.2,0.5l-2-4.6c3.8,0.7,9.2,1,16.2,1h2.2v-8.2h-7.7
        c-6.7,0-11.5,0.1-14.2,0.3L420,311.7z M432.5,394.4h5.1v-16.8h-5.1V394.4z M450.9,380.7c3,0.5,5.3,1,7,1.7c1.8,0.6,3.1,1.3,3.9,2.1
        c0.6,0.5,0.9,1.1,0.9,1.7c0,1.9-0.7,3.5-2,4.9c-1.2,1.7-2.7,2.5-4.4,2.5c-0.4,0-1.1-1.4-2.3-4.1c-1.1-2.4-2.5-4.6-4.3-6.4
        L450.9,380.7z"/>
      <path class="st0 op2" d="M143,424.7c2,0,3.7,0.7,5.1,2.2c1.4,1.5,2.2,3.3,2.2,5.6c0,2.3-0.7,4.2-2.2,5.7c-1.5,1.5-3.2,2.3-5.3,2.3
        c-2.1,0-3.8-0.7-5.3-2.2c-1.4-1.5-2.1-3.4-2.1-5.7c0-2.4,0.8-4.4,2.5-5.9C139.3,425.4,141,424.7,143,424.7z M142.8,425.6
        c-1.4,0-2.4,0.5-3.3,1.5c-1,1.2-1.5,3.1-1.5,5.5c0,2.5,0.5,4.4,1.6,5.7c0.8,1,1.9,1.5,3.2,1.5c1.4,0,2.6-0.6,3.5-1.7
        c0.9-1.1,1.4-2.9,1.4-5.2c0-2.6-0.5-4.5-1.5-5.8C145.4,426.1,144.3,425.6,142.8,425.6z M157.8,425.1h4.1l9.3,11.3v-8.7
        c0-0.9-0.1-1.5-0.3-1.7c-0.3-0.3-0.7-0.5-1.3-0.5H169v-0.4h5.3v0.4h-0.5c-0.6,0-1.1,0.2-1.4,0.6c-0.2,0.2-0.2,0.8-0.2,1.6v12.7h-0.4
        l-10-12.2v9.3c0,0.9,0.1,1.5,0.3,1.7c0.3,0.3,0.7,0.5,1.3,0.5h0.5v0.4h-5.3v-0.4h0.5c0.7,0,1.1-0.2,1.4-0.6c0.2-0.2,0.2-0.8,0.2-1.6
        v-10.5c-0.4-0.5-0.8-0.8-1-1c-0.2-0.2-0.6-0.3-1-0.5c-0.2-0.1-0.5-0.1-1-0.1V425.1z M194.7,436l0.4,0.1l-1.3,4.1H182v-0.4h0.6
        c0.6,0,1.1-0.2,1.4-0.6c0.2-0.2,0.2-0.8,0.2-1.7v-9.7c0-0.9-0.1-1.5-0.3-1.8c-0.3-0.3-0.7-0.5-1.3-0.5H182v-0.4h6.9v0.4
        c-0.8,0-1.4,0.1-1.7,0.2c-0.3,0.2-0.5,0.4-0.7,0.6c-0.1,0.2-0.2,0.8-0.2,1.7v9.5c0,0.6,0.1,1,0.2,1.3c0.1,0.2,0.2,0.3,0.4,0.3
        c0.2,0.1,0.8,0.1,1.7,0.1h1.1c1.2,0,2-0.1,2.5-0.3c0.5-0.2,0.9-0.5,1.3-0.9C193.9,437.6,194.3,437,194.7,436z M211.1,425.1h5.3v0.4
        h-0.3c-0.2,0-0.5,0.1-0.8,0.3c-0.4,0.2-0.7,0.4-1,0.7c-0.3,0.3-0.7,0.8-1.1,1.6l-3.7,5.7v3.8c0,0.9,0.1,1.5,0.3,1.7
        c0.3,0.3,0.7,0.5,1.3,0.5h0.5v0.4h-6.5v-0.4h0.5c0.6,0,1.1-0.2,1.4-0.6c0.2-0.2,0.2-0.8,0.2-1.6V434l-4.2-6.3
        c-0.5-0.7-0.8-1.2-1-1.4c-0.2-0.2-0.5-0.4-1.1-0.7c-0.2-0.1-0.4-0.1-0.6-0.1v-0.4h6.5v0.4h-0.3c-0.4,0-0.7,0.1-1,0.2
        c-0.3,0.2-0.4,0.4-0.4,0.7c0,0.3,0.2,0.8,0.7,1.5l3.2,4.9l3-4.7c0.4-0.7,0.7-1.2,0.7-1.6c0-0.2-0.1-0.4-0.2-0.6
        c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.1-0.9-0.1V425.1z M248.2,425.1l0.2,3.5H248c-0.1-0.6-0.2-1.1-0.3-1.3
        c-0.2-0.4-0.5-0.8-0.9-1c-0.4-0.2-0.9-0.3-1.5-0.3H243v11.5c0,0.9,0.1,1.5,0.3,1.7c0.3,0.3,0.7,0.5,1.3,0.5h0.5v0.4h-6.4v-0.4h0.5
        c0.6,0,1.1-0.2,1.4-0.6c0.2-0.2,0.2-0.8,0.2-1.6V426h-1.8c-0.7,0-1.2,0-1.5,0.2c-0.4,0.1-0.7,0.4-1,0.8c-0.3,0.4-0.4,0.9-0.5,1.6
        h-0.4l0.2-3.5H248.2z M260.5,432.1h7.1v-4.3c0-0.8,0-1.3-0.1-1.5c-0.1-0.2-0.2-0.3-0.5-0.5c-0.3-0.2-0.7-0.3-1-0.3h-0.5v-0.4h6.5
        v0.4h-0.5c-0.4,0-0.7,0.1-1,0.3c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.1,0.7-0.1,1.5v9.8c0,0.8,0,1.3,0.1,1.5
        c0.1,0.2,0.2,0.3,0.5,0.5c0.3,0.2,0.7,0.3,1,0.3h0.5v0.4h-6.5v-0.4h0.5c0.6,0,1.1-0.2,1.4-0.5c0.2-0.2,0.3-0.8,0.3-1.7v-4.6h-7.1
        v4.6c0,0.8,0,1.3,0.1,1.5c0.1,0.2,0.2,0.3,0.5,0.5c0.3,0.2,0.7,0.3,1,0.3h0.6v0.4h-6.5v-0.4h0.5c0.6,0,1.1-0.2,1.4-0.5
        c0.2-0.2,0.3-0.8,0.3-1.7v-9.8c0-0.8-0.1-1.3-0.1-1.5c-0.1-0.2-0.2-0.3-0.5-0.5c-0.3-0.2-0.7-0.3-1-0.3h-0.5v-0.4h6.5v0.4h-0.6
        c-0.4,0-0.7,0.1-1,0.3c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.1,0.7-0.1,1.5V432.1z M284.1,425.9v6h3.3c0.9,0,1.4-0.1,1.7-0.4
        c0.4-0.3,0.6-0.9,0.7-1.8h0.4v5.3h-0.4c-0.1-0.7-0.2-1.2-0.3-1.4c-0.1-0.3-0.4-0.5-0.7-0.6c-0.3-0.2-0.8-0.2-1.4-0.2h-3.3v5
        c0,0.7,0,1.1,0.1,1.2c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.4,0.1,0.9,0.1h2.6c0.9,0,1.5-0.1,1.9-0.2c0.4-0.1,0.8-0.4,1.1-0.7
        c0.5-0.5,0.9-1.2,1.4-2.1h0.4l-1.3,3.8h-11.7v-0.4h0.5c0.4,0,0.7-0.1,1-0.3c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.1-0.7,0.1-1.5v-9.8
        c0-1-0.1-1.5-0.3-1.8c-0.3-0.3-0.7-0.4-1.3-0.4h-0.5v-0.4h11.7l0.2,3.3h-0.4c-0.2-0.8-0.3-1.3-0.5-1.6s-0.5-0.5-0.8-0.7
        c-0.3-0.1-0.8-0.2-1.6-0.2H284.1z M323.4,432.5c1.1,0.2,1.8,0.6,2.4,1.1c0.7,0.7,1.1,1.5,1.1,2.5c0,0.8-0.2,1.5-0.7,2.2
        c-0.5,0.7-1.1,1.2-2,1.5c-0.8,0.3-2.1,0.5-3.8,0.5h-7.2v-0.4h0.6c0.6,0,1.1-0.2,1.4-0.6c0.2-0.3,0.3-0.8,0.3-1.7v-9.8
        c0-0.9-0.1-1.5-0.3-1.8c-0.3-0.3-0.7-0.5-1.3-0.5h-0.6v-0.4h6.6c1.2,0,2.2,0.1,2.9,0.3c1.1,0.3,2,0.7,2.6,1.4
        c0.6,0.7,0.9,1.5,0.9,2.3c0,0.8-0.2,1.4-0.7,2C325,431.8,324.3,432.2,323.4,432.5z M317.5,431.9c0.3,0.1,0.6,0.1,0.9,0.1
        c0.4,0,0.7,0,1.2,0c1.1,0,1.9-0.1,2.5-0.4c0.5-0.2,1-0.6,1.3-1.1c0.3-0.5,0.4-1,0.4-1.6c0-0.9-0.4-1.6-1.1-2.3
        c-0.7-0.6-1.8-0.9-3.2-0.9c-0.7,0-1.4,0.1-2,0.2V431.9z M317.5,439.1c0.9,0.2,1.7,0.3,2.6,0.3c1.4,0,2.4-0.3,3.1-0.9
        c0.7-0.6,1.1-1.4,1.1-2.2c0-0.6-0.2-1.2-0.5-1.7c-0.3-0.5-0.8-1-1.6-1.3c-0.7-0.3-1.6-0.5-2.7-0.5c-0.5,0-0.9,0-1.2,0
        c-0.3,0-0.6,0-0.8,0.1V439.1z M339.8,425.9v6h3.3c0.9,0,1.4-0.1,1.7-0.4c0.4-0.3,0.6-0.9,0.7-1.8h0.4v5.3h-0.4
        c-0.1-0.7-0.2-1.2-0.3-1.4c-0.1-0.3-0.4-0.5-0.7-0.6c-0.3-0.2-0.8-0.2-1.4-0.2h-3.3v5c0,0.7,0,1.1,0.1,1.2c0.1,0.1,0.2,0.3,0.3,0.3
        c0.1,0.1,0.4,0.1,0.9,0.1h2.6c0.9,0,1.5-0.1,1.9-0.2c0.4-0.1,0.8-0.4,1.1-0.7c0.5-0.5,0.9-1.2,1.4-2.1h0.4l-1.3,3.8h-11.7v-0.4h0.5
        c0.4,0,0.7-0.1,1-0.3c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.1-0.7,0.1-1.5v-9.8c0-1-0.1-1.5-0.3-1.8c-0.3-0.3-0.7-0.4-1.3-0.4h-0.5
        v-0.4h11.7l0.2,3.3h-0.4c-0.2-0.8-0.3-1.3-0.5-1.6s-0.5-0.5-0.8-0.7c-0.3-0.1-0.8-0.2-1.6-0.2H339.8z M366.4,424.7v5.2H366
        c-0.1-1-0.4-1.8-0.7-2.4c-0.3-0.6-0.8-1.1-1.5-1.4c-0.6-0.4-1.3-0.5-2-0.5c-0.8,0-1.4,0.2-1.9,0.7c-0.5,0.5-0.8,1-0.8,1.6
        c0,0.5,0.2,0.9,0.5,1.3c0.5,0.6,1.6,1.3,3.3,2.2c1.4,0.8,2.4,1.3,2.9,1.7c0.5,0.4,0.9,0.9,1.2,1.4c0.3,0.6,0.4,1.1,0.4,1.7
        c0,1.1-0.4,2.1-1.3,3c-0.9,0.8-2,1.2-3.4,1.2c-0.4,0-0.9,0-1.2-0.1c-0.2,0-0.7-0.2-1.4-0.4c-0.7-0.2-1.2-0.4-1.4-0.4
        c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.7h-0.4v-5.2h0.4c0.2,1.1,0.5,1.9,0.8,2.4c0.3,0.5,0.8,1,1.5,1.3
        c0.7,0.4,1.4,0.5,2.2,0.5c0.9,0,1.7-0.2,2.2-0.7c0.5-0.5,0.8-1.1,0.8-1.7c0-0.4-0.1-0.7-0.3-1.1c-0.2-0.4-0.5-0.7-1-1.1
        c-0.3-0.2-1.1-0.7-2.4-1.4c-1.3-0.7-2.2-1.3-2.8-1.7c-0.6-0.4-1-0.9-1.3-1.4c-0.3-0.5-0.4-1.1-0.4-1.7c0-1.1,0.4-2,1.3-2.8
        c0.8-0.8,1.9-1.2,3.2-1.2c0.8,0,1.7,0.2,2.6,0.6c0.4,0.2,0.7,0.3,0.9,0.3c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.3-0.7H366.4z
        M388.8,425.1l0.2,3.5h-0.4c-0.1-0.6-0.2-1.1-0.3-1.3c-0.2-0.4-0.5-0.8-0.9-1c-0.4-0.2-0.9-0.3-1.5-0.3h-2.1v11.5
        c0,0.9,0.1,1.5,0.3,1.7c0.3,0.3,0.7,0.5,1.3,0.5h0.5v0.4h-6.4v-0.4h0.5c0.6,0,1.1-0.2,1.4-0.6c0.2-0.2,0.2-0.8,0.2-1.6V426h-1.8
        c-0.7,0-1.2,0-1.5,0.2c-0.4,0.1-0.7,0.4-1,0.8c-0.3,0.4-0.4,0.9-0.5,1.6h-0.4l0.2-3.5H388.8z"/>
      <g class="icon">
        <g>
          <g  class="letter4">
            <polyline class="st1" points="244.7,169.9 231.2,183.4 231.2,244 249.3,262 267.3,244 267.3,183.4 253.8,169.9 			"/>
            <polygon class="st1" points="249.3,165.4 242.8,158.9 180.2,158.9 180.2,204 225.3,204 225.3,141.4 200.8,116.9 140.2,116.9 
              122.2,135 140.2,153 200.8,153 225.3,128.5 225.3,65.9 180.2,65.9 180.2,111 242.8,111 249.3,104.5 255.7,111 318.3,111 
              318.3,65.9 273.2,65.9 273.2,128.5 297.7,153 358.3,153 376.3,135 358.3,116.9 297.7,116.9 273.2,141.4 273.2,204 318.3,204 
              318.3,158.9 255.7,158.9 			"/>
            <polyline class="st1" points="253.8,100 267.3,86.5 267.3,25.9 249.3,7.9 231.2,25.9 231.2,86.5 244.7,100 			"/>
            <polyline class="st1" points="225.3,108.7 242.1,108.7 249.3,101.6 256.4,108.7 273.2,108.7 			"/>
            <polyline class="st1" points="252.3,98.5 265,85.9 265,26.5 249.3,10.8 233.6,26.5 233.6,85.9 246.2,98.5 			"/>
            <polyline class="st1" points="250.8,97 262.6,85.1 262.6,27.3 249.3,13.9 235.9,27.3 235.9,85.1 247.8,97 			"/>
            <polyline class="st1" points="225.3,106.4 241.3,106.4 249.2,98.5 257.2,106.4 273.2,106.4 			"/>
            <polyline class="st1" points="225.3,104.1 240.6,104.1 260.3,84.4 260.3,28 249.3,16.9 238.2,28 238.2,84.4 257.9,104.1 
              273.2,104.1 			"/>
            <polyline class="st1" points="217.4,133.5 223,127.8 223,68.5 182.8,68.5 182.8,108.7 218.4,108.7 			"/>
            <polyline class="st1" points="218.4,161.2 182.8,161.2 182.8,201.4 223,201.4 223,142.1 200.2,119.3 140.8,119.3 125.1,135 
              140.8,150.7 200.2,150.7 212.8,138 			"/>
            <polyline class="st1" points="246.2,171.4 233.6,184 233.6,243.4 249.3,259.1 265,243.4 265,184 252.3,171.4 			"/>
            <polyline class="st1" points="273.1,161.2 256.4,161.2 249.3,168.3 242.1,161.2 225.3,161.2 			"/>
            <polyline class="st1" points="285.7,138 298.3,150.7 357.7,150.7 373.4,135 357.7,119.3 298.3,119.3 275.5,142.1 275.5,201.4 
              315.7,201.4 315.7,161.2 280.1,161.2 			"/>
            <polyline class="st1" points="280.1,108.7 315.7,108.7 315.7,68.5 275.5,68.5 275.5,127.8 281.2,133.5 			"/>
            <polyline class="st1" points="218.4,163.5 185.3,163.5 185.3,198.9 220.7,198.9 220.7,142.9 199.4,121.6 141.6,121.6 128.2,135 
              141.6,148.3 199.4,148.3 220.7,127 220.7,71 185.3,71 185.3,106.4 218.4,106.4 			"/>
            <polyline class="st1" points="280.1,106.4 313.2,106.4 313.2,71 277.8,71 277.8,127 299.1,148.3 356.9,148.3 370.3,135 
              356.9,121.6 299.1,121.6 277.8,142.9 277.8,198.9 313.2,198.9 313.2,163.5 280.1,163.5 			"/>
            <polyline class="st1" points="218.4,165.8 187.9,165.8 187.9,196.3 218.4,196.3 218.4,143.6 198.7,123.9 142.3,123.9 131.2,135 
              142.3,146 198.7,146 218.4,126.3 218.4,73.6 187.9,73.6 187.9,104.1 218.4,104.1 			"/>
            <polyline class="st1" points="273.1,165.8 257.9,165.8 238.2,185.5 238.2,241.9 249.3,253 260.3,241.9 260.3,185.5 240.6,165.8 
              225.3,165.8 			"/>
            <polyline class="st1" points="280.1,104.1 310.6,104.1 310.6,73.6 280.1,73.6 280.1,126.3 299.8,146 356.2,146 367.3,135 
              356.2,123.9 299.8,123.9 280.1,143.6 280.1,196.3 310.6,196.3 310.6,165.8 280.1,165.8 			"/>
            <polyline class="st1" points="247.8,172.9 235.9,184.8 235.9,242.6 249.3,256 262.6,242.6 262.6,184.8 250.8,172.9 			"/>
            <polyline class="st1" points="273.1,163.5 257.2,163.5 249.3,171.4 241.3,163.5 225.3,163.5 			"/>
          </g>
          <polyline class="st2 letter3" points="157.7,123.9 157.7,164.4 219.8,226.5 260.3,226.5 		"/>
          <polyline class="st2 letter2" points="267.3,226.5 278.7,226.5 340.8,164.4 340.8,105.5 278.7,43.4 219.8,43.4 157.7,105.5 157.7,116.9 		
            "/>
        </g>
          <polygon class="st3 letter1" points="266.2,151.9 266.2,147.9 258,139.7 252.2,139.7 244.9,147 249.3,151.4 253.7,147 246.3,139.7 
            240.5,139.7 232.3,147.9 232.3,151.9 236.3,151.9 244.5,143.7 244.5,137.9 237.2,130.6 232.8,135 237.2,139.4 244.5,132 
            244.5,126.2 236.3,118 232.3,118 232.3,118 232.3,122 240.5,130.2 246.3,130.2 253.7,122.9 249.3,118.5 244.9,122.9 252.2,130.2 
            258,130.2 266.2,122 266.2,118 262.2,118 254,126.2 254,132 261.3,139.4 265.7,135 261.3,130.6 254,137.9 254,143.7 262.2,151.9 
            266.2,151.9 		"/>
      </g>
    </svg>

    <img
      src="./s1/wlogo.png"
      alt="泰御天鑄"
      class="absolute logo-t"
    >
    <img
      src="./s1/wlogo.png"
      alt="泰御天鑄"
      class="absolute logo-b"
    >
    -->
    <img src="./s1/logoty.png" alt="泰御天鑄" class="absolute logo">
    <h3 class="absolute title" data-aos="fade-up" data-aos-delay="800">泰新系統以一流的機電環控<br />建立安心居住、放心生活的家園</h3>
  </div>
</template>
<style lang="scss" scoped>
.logo {
  color: #68361a;
  .icon {
    fill: none;
    stroke: currentColor;
    stroke-miterlimit: 10;
  }
  .st0 {
    fill: currentColor;
  }
  .st1 {
    stroke-width: 1.06;
  }
  .st2 {
    stroke-width: 9.1272;
  }
  .st3 {
    stroke-width: 1.8791;
  }
  .letter1 {
    stroke-dasharray: 310;
    stroke-dashoffset: 310;
    animation: letterDraw 2s 1s linear forwards;
  }
  .letter2 {
    stroke-dasharray: 410;
    stroke-dashoffset: 410;
    animation: letterDraw 2.2s 3.8s linear forwards;
  }
  .letter3 {
    stroke-dasharray: 170;
    stroke-dashoffset: 170;
    animation: letterDraw 0.8s 3s linear forwards;
  }
  .letter4 {
    stroke-dasharray: 1390;
    stroke-dashoffset: 1390;
    animation: letterDraw 5s 3s linear forwards;
  }
  .op1 {
    opacity: 0;
    animation: op 1s 3s linear forwards;
  }
  .op2 {
    opacity: 0;
    animation: op 0.5s 4s linear forwards;
  }
}
@keyframes letterDraw {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes op {
  to {
    opacity: 1;
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
.relative {
  height: 100vh;
  // overflow: hidden;
  position: relative;
  //height: size(1080);
  background-attachment: fixed;
  background-image: linear-gradient(
    to right,
    #edbd5b,
    #edbc5b 29%,
    #d6a045 66%,
    #bf842e
  );
}
.logo {
  height: size(300);
  top: calc(50% - 10vw);
  left: 0;
  right: 0;
  margin: 0 auto;
}

.logo-t {
  width: size(472);
  right: size(292 - 472);
  top: size(319 - 472);
  opacity: 0.5;
}

.logo-b {
  width: size(472);
  left: size(275 - 472);
  bottom: size(-150);
  opacity: 0.5;
  z-index: 2;
}

.title {
  text-shadow: 0 3px 12px rgba(0, 0, 0, 0.4);
  font-size: calc(100vh * 55 / 1080);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: 0.35em;
  text-align: center;
  color: #ffffff;
  top: calc(100vh * 681 / 1080);
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0;
  animation: op 0.5s 5s linear forwards;
}

@media only screen and (max-width: 1440px) {
  .bg-img {
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .relative {
    height: size-m(441 + 244);
  }

  .logo {
    height: auto;
    width: size-m(300);
    top: size-m(150);
    left: size-m(37.5);
    right: auto;
    margin: 0 auto;
  }

  .logo-t {
    width: size-m(375);
    right: 0;
    top: size-m(129 - 375);
    opacity: 0.5 !important;
  }

  .logo-b {
    width: size-m(244);
    left: 0;
    right: 0;
    margin: 0 auto;
    top: size-m(441);
    opacity: 0.5 !important;
  }

  .title {
    text-shadow: 0 3px 12px rgba(0, 0, 0, 0.4);
    font-size: size-m(19);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 4.4px;
    text-align: center;
    color: #ffffff;
    top: size-m(345);
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
</style>
<script>
// @ is an alias to /src
import { isMobile } from '@/utils'

export default {
  name: 'section1',
  data() {
    return {
      isMobile,
    }
  },

  methods: {},

  created() {},

  mounted() {},

  computed: {},
}
</script>
