export default {
  address: '台中公益路二段246號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3640.586915980148!2d120.6426028149891!3d24.151140984393436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34693d9512dc69eb%3A0x3cd2778ed575c39d!2zNDA45Y-w5Lit5biC5Y2X5bGv5Y2A5YWs55uK6Lev5LqM5q61MjQ26Jmf!5e0!3m2!1szh-TW!2stw!4v1609725579885!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/CsQrG2wswGBCyGus9',
  phone: '04-22588899',
  fbLink: 'https://www.facebook.com/泰御建設-115090143524810',
  fbMessage: 'http://m.me/115090143524810/',
  caseName: '泰御天鑄',
  indigatorLength: 9,

  houseInfos: [
    ['地址', '台中公益路二段246號'],
    ['電話', '04 2258-8899'],
  ],

  gtmCode: [' '], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
} 